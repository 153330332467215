/* eslint-disable no-nested-ternary */
import { getDateFormat } from '@hc/dayjs';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { backoffice_stepper_style } from './style';

function BackofficeStepper(props) {
  const { stepperData = [] } = props;

  return (
    <Box sx={{ pl: '10px', pr: 2.5, pb: 3, mt: 1 }}>
      {stepperData?.map((val, index) => (
        <Box key={index} sx={backoffice_stepper_style.contentWrapSx}>
          <Box
            sx={{
              position: 'absolute',
              // border: val?.status === 'inProgress' ? '2px solid #7A67D9' : '',
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              placeItems: 'center',
              display: 'grid',
            }}
          >
            <Box
              sx={{
                ...backoffice_stepper_style.stepNumberSx,
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #707070',
                position: 'absolute',
                width: '19px',
                height: '19px',
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                // top: 0,
                placeItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  background: '#747474 0% 0% no-repeat padding-box',
                  opacity: 1,
                  borderRadius: '50%',
                }}
              />
            </Box>
          </Box>
          <Box sx={backoffice_stepper_style.stepPointsSx}>
            <Typography
              sx={{
                ...backoffice_stepper_style.stepPointsHeadingSx,
              }}
              backgroundColor={`${
                val?.status_id === 1
                  ? '#ffffe6'
                  : val?.status_id === 2
                  ? '#E8FCF0'
                  : '#FEEAEA'
              }`}
              color={`${
                val?.status_id === 1
                  ? '#FACC15'
                  : val?.status_id === 2
                  ? '#4CAF50'
                  : '#F44F5A'
              }`}
            >
              {val?.status_name}
            </Typography>
            <Typography sx={backoffice_stepper_style.doctorNameSx}>
              {val?.doctor_name}
            </Typography>
            <Typography sx={backoffice_stepper_style.dateTimeSx}>
              {`at ${getDateFormat(val?.at_time, 'DD MMM, YYYY')}  `}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

BackofficeStepper.propTypes = {
  stepperData: PropTypes.array,
};

export { BackofficeStepper };

