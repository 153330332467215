import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TickIcon } from '../../../atoms';
import { multiSelectToggle_style } from './style';

function MultiSelectToggle(props) {
  const {
    className = '',
    rootStyle = {},
    buttonContent = [],
    selectbuttonStyle = {},
    buttonStyle = {},
    handleValueChange = () => {},
    value,
    isError,
    errorMessage,
    isTickShow = true,
    plan,
    ...rest
  } = props;
  const [formats, setFormats] = useState(value);

  const handleFormat = (newValue) => {
    setFormats(newValue);
    handleValueChange(newValue);
  };
  const tick = formats.length > 0 ? formats : value;

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    display: 'flex',
    gap: '10px',
    borderRadius: '4px',
    border: '1px !important',
    '& .MuiButtonBase-root': {
      // width: '42px',
      borderRadius: '10px !important',
      border: '1px !important',
    },

    '& .MuiToggleButtonGroup-grouped': {
      border: '1px !important',
      color: '#353448',
      cursor: 'pointer',
      padding: '7px 12px',
      textTransform: 'capitalize',
      borderRadius: '10px',
      background: '#EEFBF6',
      '&:hover': {
        background: '#EEFBF6',
        border: '1px !important ',
      },
      '&:focus': {
        outline: 'none',
      },
      '&.Mui-selected': {
        background: '#EEFBF6',
        border: '1px solid #49C792 !important',
        '&:hover': {
          background: '#EEFBF6',
        },
      },
      ...buttonStyle,
      ...selectbuttonStyle,
    },
  }));

  useEffect(() => {
    setFormats(value);
  }, [plan]);
  return (
    <Box
      sx={{
        ...multiSelectToggle_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <StyledToggleButtonGroup
        selectbuttonStyle={selectbuttonStyle}
        buttonStyle={buttonStyle}
        value={formats.length > 0 ? formats : value}
        onChange={(e, newValue) => handleFormat(newValue)}
      >
        {buttonContent.map((option, id) => (
          <ToggleButton
            data-testId={`toggle_${id}`}
            disabled={option.disabled}
            value={option?.value}
            key={id}
            sx={{ fontSize: '14px' }}
          >
            {isTickShow && tick.includes(option?.value) && (
              <TickIcon rootStyle={{ width: '14px', height: '14px' }} />
            )}
            &nbsp; {option.name}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

MultiSelectToggle.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MultiSelectToggle };
