import { Box, Typography } from '@mui/material';
// import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import { transactionsTable_style } from './style';

//

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell,i) => (
          <TableCell
          key={i}
            sx={{
              borderBottom: '1px solid #E3E3E3',
              boxShadow:
                headCell.id === 'transaction_status'
                  ? '1px 0px  #E3E3E3 inset'
                  : null,
              position:
                headCell.id === 'transaction_status' ||
                headCell.id === 'settelment_status'
                  ? 'sticky'
                  : '',
              right: headCell.id === 'settelment_status' ? '0px' : '182px',
              backgroundColor:
                headCell.id === 'total_earnings' ? '#EBFAF7' : '#fff',
              color: headCell.id === 'total_earnings' ? '#007965' : '',
            }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                whiteSpace: 'nowrap',
                pl: headCell.id === 'transaction_iD' ? 1 : null,
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function getComponentForCell(type, lable, style) {
  switch (type) {
    case 'BoldCell':
      return (
        <TableCell
          align="right"
          sx={{ ...transactionsTable_style.tableCellBold, ...style }}
        >
          ₹{lable}
        </TableCell>
      );
    case 'HighLightedCell':
      return (
        <TableCell
          align="right"
          sx={{ ...transactionsTable_style.hightLightedCell, ...style }}
        >
          ₹{lable}
        </TableCell>
      );

    case 'StickyCell0':
      return (
        <TableCell align="right" sx={transactionsTable_style.StickyCell0}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{
                ...transactionsTable_style.transactionStatusSx,
                backgroundColor:
                  lable === 'Success' || lable === 'Refund'
                    ? '#E8FCF0'
                    : lable === 'Failed'
                    ? '#FEEAEA '
                    : '#fff',
                color:
                  lable === 'Success' || lable === 'Refund'
                    ? '#4CAF50'
                    : lable === 'Failed'
                    ? '#F44F5A '
                    : '#0E1824',
              }}
            >
              {lable}
            </Typography>
          </Box>
        </TableCell>
      );
    case 'StickyCell1':
      return (
        <TableCell align="right" sx={transactionsTable_style.StickyCell1}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{
                ...transactionsTable_style.transactionStatusSx,
                backgroundColor:
                  lable === 'Success' || lable === 'Refund'
                    ? '#E8FCF0'
                    : lable === 'Failed'
                    ? '#FEEAEA '
                    : '#fff',
                color:
                  lable === 'Success' || lable === 'Refund'
                    ? '#4CAF50'
                    : lable === 'Failed'
                    ? '#F44F5A '
                    : '#0E1824',
              }}
            >
              {lable}
            </Typography>
          </Box>
        </TableCell>
      );
    case 'Normal':
      return (
        <TableCell
          align="left"
          sx={{ ...transactionsTable_style.tableCellSx, ...style }}
        >
          {lable}
        </TableCell>
      );

    default:
      return (
        <TableCell sx={{ minWidth: '10px' }} align="left">
          {lable}
        </TableCell>
      );
  }
}

function TransactionsTable(props) {
  const { headCells, rows } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <>
      <Box sx={transactionsTable_style.paperContainer}>
        <TableContainer
          sx={{ overflowX: 'auto', maxWidth: '82vw', borderRadius: '10px' }}
        >
          <Table sx={{ width: '100%' }}>
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={{ overflowX: 'auto' }}>
              {visibleRows.map((row,i) => {
                return (
                  <TableRow
                    sx={transactionsTable_style.tableRow}
                    tabIndex={-1}
                    key={i}
                  >
                    {headCells.map((column, i) => (
                      <React.Fragment key={i}>
                        {getComponentForCell(column.type, row[column.id])}
                      </React.Fragment>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

TransactionsTable.propTypes = {};

export { TransactionsTable };

