import { Box, Typography, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import { logoutModal_style } from './style';
import { Button } from '../../../atoms';

function LogoutModal(props) {
  const { isModalOpen, handleClose, handleDelete } = props;

  return (
    <Box sx={logoutModal_style.rootSx}>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box sx={logoutModal_style.totalModalSx}>
          <Box sx={logoutModal_style.totalBoxSx}>
            <Box mt={2}>
              <Typography sx={logoutModal_style.description}>
                {' '}
                Are you sure you want to Logout?
              </Typography>
              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                }}
              >
                <Button
                  sx={logoutModal_style.closebuttonSx}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  sx={logoutModal_style.DeletebuttonSx}
                  onClick={handleDelete}
                >
                  LOGOUT
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

LogoutModal.propTypes = {
  isModalOpen: PropTypes.bool,
};

export { LogoutModal };
