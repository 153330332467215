import { Drawer } from '@hc/ui/atoms';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { BackofficeStepper } from '../backofficeStepper';
import { drugAuditStepperDrawer_style } from './style';

function DrugAuditStepperDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    stepperData = [],
    drugAuditStepperDrawerOpen = false,
    closeDrugAuditStepperDrawer = () => false,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...drugAuditStepperDrawer_style.rootSx,
        ...rootStyle,
        paddingTop: '100px',
      }}
      className={`${className}`}
      {...rest}
    >
      {drugAuditStepperDrawerOpen && (
        <Drawer
          show={drugAuditStepperDrawerOpen}
          onCloseDrawer={closeDrugAuditStepperDrawer}
          anchor="right"
          isCloseIconRequired
          header="Log"
          headerStyle={drugAuditStepperDrawer_style.headerSx}
        >
          <Box sx={drugAuditStepperDrawer_style.boxRootSx}>
            <BackofficeStepper stepperData={stepperData} />
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

DrugAuditStepperDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  drugAuditStepperDrawerOpen: PropTypes.func,
  closeDrugAuditStepperDrawer: PropTypes.func,
  stepperData: PropTypes.array,
};

export { DrugAuditStepperDrawer };

