import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useAwarenessManagement = create((set, get) => ({
  awarenessManagementState: {
    awarenessManagementData: { data: [], count: '0' },
    categoryManagementData: { data: [], count: '0' },
    awarenessManagement: {
      id: '',
      name: '',
      image: '',
      error: {
        name: '',
        image: '',
      },
    },
    categoryManagement: {
      id: '',
      awareness_id: '',
      name: '',
      image: '',
      note_type: 'content',
      contentData: [
        {
          awareness_category_note_image: '',
          title: '',
          title_color_code: '',
          content: '',
          content_color_code: '',
          gradient_left_color_code: '',
          gradient_right_color_code: '',
        },
      ],
      error: {
        name: '',
        image: '',
        note_type: '',
      },
    },
  },
  loading: false,
  error: false,

  getAllAwarenessManagementData: async (
    searchValue,
    limit = 10,
    offset = 0
  ) => {
    try {
      const { awarenessManagementState } = get();
      set({ loading: true });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllAwarenessManagementData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/awareness/get/all?search=${searchValue}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        const arr = [];
        if (Array.isArray(data?.results) && data?.results?.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const val of data?.results) {
            const obj = {
              created_at: val?.created_at ?? '',
              awareness_image: val?.awareness_image ?? '',
              awareness_name: val?.awareness_name ?? '',
              id: val?.awareness_id ?? '',
              awareness_id: val?.awareness_id ?? '',
              category_count: val?.category_count,
              active_status: val?.active_status ?? '',
              updated_at: val?.updated_at ?? '',
            };
            arr.push(obj);
          }
        }

        set(() => ({
          loading: false,
          awarenessManagementState: {
            ...awarenessManagementState,
            awarenessManagementData: {
              data: arr ?? [],
              count: data?.awareness_count ?? 0,
            },
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  getAllCategoryData: async (
    awareness_id,
    searchValue,
    limit = 10,
    offset = 0
  ) => {
    try {
      const { awarenessManagementState } = get();
      const { categoryManagement } = awarenessManagementState;
      set({ loading: true });

      const response = await httpRequest(
        'get',
        `${envConfig.api_url}/backoffice/awareness-category/get/all?awareness_id=${awareness_id}&search=${searchValue}&limit=${limit}&offset=${offset}`,
        {},
        true
      );

      if (response?.data?.status?.code === 200) {
        const arr = [];
        if (
          Array.isArray(response?.data?.data?.results) &&
          response?.data?.data?.results?.length > 0
        ) {
          // eslint-disable-next-line no-restricted-syntax
          for (const val of response?.data?.data?.results) {
            const obj = {
              created_at: val?.created_at ?? '',
              awareness_category_image: val?.awareness_category_image ?? '',
              awareness_category_name: val?.awareness_category_name ?? '',
              id: val?.awareness_category_id ?? '',
              awareness_category_id: val?.awareness_category_id ?? '',
              category_notes_count: val?.category_notes_count,
              active_status: val?.active_status ?? '',
              updated_at: val?.updated_at ?? '',
              note_type: val?.note_type ?? '',
            };
            arr.push(obj);
          }
        }

        set(() => ({
          loading: false,
          awarenessManagementState: {
            ...awarenessManagementState,
            categoryManagement: {
              ...categoryManagement,
              awareness_id,
            },
            categoryManagementData: {
              data: arr ?? [],
              count: response?.data?.data?.awareness_category_count ?? 0,
            },
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  getAwarenessDataById: async (data) => {
    const { awarenessManagementState } = get();

    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        awarenessManagement: {
          id: data?.id ?? '',
          name: data?.awareness_name ?? '',
          image: data?.awareness_image ?? '',
          error: {
            name: '',
            image: '',
          },
        },
      },
    });
  },

  getNoteDataByCategoryId: async (category_id) => {
    try {
      const { awarenessManagementState } = get();
      // set({ loading: true });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getNoteDataByCategoryId'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/awareness/get/notes-by-category-id?category_id=${category_id}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        const arr = [];
        if (
          Array.isArray(data?.categoryNotesData) &&
          data?.categoryNotesData?.length > 0
        ) {
          // eslint-disable-next-line no-restricted-syntax
          for (const val of data?.categoryNotesData) {
            const obj = {
              // id: val?.id ?? '',
              awareness_category_note_image:
                val?.awareness_category_note_image ?? '',

              title: val?.title ?? '',
              title_color_code: val?.title_color_code ?? '',

              content: val?.content ?? '',
              content_color_code: val?.content_color_code ?? '',

              gradient_left_color_code: val?.gradient_left_color_code ?? '',
              gradient_right_color_code: val?.gradient_right_color_code ?? '',
            };
            arr.push(obj);
          }
        }

        set({
          awarenessManagementState: {
            ...awarenessManagementState,
            categoryManagement: {
              id: data?.categoryData?.id ?? '',
              awareness_id: data?.categoryData?.awareness_id ?? '',
              name: data?.categoryData?.awareness_category_name ?? '',
              image: data?.categoryData?.awareness_category_image ?? '',
              note_type: data?.categoryData?.note_type ?? '',
              contentData: arr ?? [],
              error: {
                name: '',
                image: '',
                note_type: '',
              },
            },
          },
        });
      }
      // set(() => ({ loading: false }));
      return status?.code;
    } catch (error) {
      // set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  awarenessManagemenStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        awareness_id: payload?.id,
        active_status: payload?.status,
      };
      queryClient.invalidateQueries({
        queryKey: ['awarenessManagementStatusUpdate'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['awarenessManagementStatusUpdate', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/awareness/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message ?? 'Status Updated Successfully');
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  categoryManagemenStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        awareness_category_id: payload?.id,
        active_status: payload?.status,
      };
      queryClient.invalidateQueries({
        queryKey: ['categoryManagemenStatusUpdate'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['categoryManagemenStatusUpdate', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/awareness-category/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message ?? 'Status Updated Successfully');
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertAwarenessManagement: async () => {
    try {
      const { awarenessManagementState } = get();
      const { awarenessManagement } = awarenessManagementState;
      set({ loading: true });
      const payloadData = {
        id: awarenessManagement?.id || '',
        awareness_name: awarenessManagement?.name ?? '',
        awareness_image: awarenessManagement?.image ?? '',
      };

      queryClient.invalidateQueries({
        queryKey: ['upsertAwarenessManagement'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['upsertAwarenessManagement'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/awareness/upsert`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Awareness Name Already Exist') {
          toast.error(data?.status?.message);
          set({ loading: false });
          return 201;
        }
        toast.success(
          data?.status?.message ??
            `Awareness ${
              awarenessManagement.id ? 'Updated' : 'Created'
            } Successfully`
        );
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertCategoryManagement: async () => {
    try {
      const { awarenessManagementState } = get();
      const { categoryManagement } = awarenessManagementState;
      set({ loading: true });
      const payloadData = {
        id: categoryManagement?.id ?? '',
        awareness_id: categoryManagement?.awareness_id || '',
        awareness_category_name: categoryManagement?.name ?? '',
        awareness_category_image: categoryManagement?.image ?? '',
        note_type: categoryManagement?.note_type ?? '',
        awareness_category_notes_data:
          Array.isArray(categoryManagement?.contentData) &&
          categoryManagement?.contentData?.length > 0
            ? categoryManagement?.contentData
            : [],
      };

      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/backoffice/awareness-category/upsert`,
        {
          ...payloadData,
        },
        true
      );

      if (response?.data?.status?.code === 200) {
        if (
          response?.data?.status?.message ===
          'Awareness Category Name Already Exist'
        ) {
          toast.error(response?.data?.status?.message);
          set({ loading: false });
          return 201;
        }
        toast.success(
          response?.data?.status?.message ??
            `Awareness Category ${
              categoryManagement.id ? 'Updated' : 'Created'
            } Successfully`
        );
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  isIamValidToSave: () => {
    const { awarenessManagementState } = get();
    const { awarenessManagement } = awarenessManagementState;
    const awarenessManagementCopy = JSON.parse(
      JSON.stringify(awarenessManagement)
    );

    let isValid = true;
    const error = awarenessManagementCopy?.error;

    // checking awareness name
    if (!awarenessManagementCopy?.name) {
      isValid = false;
      error.name = 'Please enter name';
    } else {
      error.plan_name = '';
    }
    // checking plan image
    if (!awarenessManagementCopy?.image) {
      isValid = false;
      error.image = 'Please upload image';
    } else {
      error.image = '';
    }

    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        awarenessManagement: {
          ...awarenessManagement,
          error,
        },
      },
    });

    return isValid;
  },

  isIamValidToCategorySave: () => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    const categoryManagementCopy = JSON.parse(
      JSON.stringify(categoryManagement)
    );

    let isValid = true;
    const error = categoryManagementCopy?.error;

    // checking awareness name
    if (!categoryManagementCopy?.name) {
      isValid = false;
      error.name = 'Please enter name';
    } else {
      error.plan_name = '';
    }
    // checking plan image
    if (!categoryManagementCopy?.image) {
      isValid = false;
      error.image = 'Please upload image';
    } else {
      error.image = '';
    }

    // checking note_type
    if (!categoryManagementCopy?.note_type) {
      isValid = false;
      error.note_type = 'Please select note type';
    } else {
      error.note_type = '';
    }

    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          error,
        },
      },
    });

    return isValid;
  },

  handleAwarenessManagementFormChange: (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    const { awarenessManagementState } = get();
    const { awarenessManagement } = awarenessManagementState;
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        awarenessManagement: {
          ...awarenessManagement,
          [key]: value,
        },
      },
    });
  },

  handleCategoryManagementFormChange: (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;

    if (key === 'note_type') {
      set({
        awarenessManagementState: {
          ...awarenessManagementState,
          categoryManagement: {
            ...categoryManagement,
            note_type: value,
            contentData: [
              {
                awareness_category_note_image: '',
                title: '',
                title_color_code: '',
                content: '',
                content_color_code: '',
                gradient_left_color_code: '',
                gradient_right_color_code: '',
              },
            ],
          },
        },
      });
    } else {
      set({
        awarenessManagementState: {
          ...awarenessManagementState,
          categoryManagement: {
            ...categoryManagement,
            [key]: value,
          },
        },
      });
    }
  },

  clearAwarenessManagementData: () => {
    const { awarenessManagementState } = get();
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        awarenessManagement: {
          id: '',
          name: '',
          image: '',
          error: {
            name: '',
            image: '',
          },
        },
      },
    });
  },

  clearCategoryManagementData: () => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          id: '',
          name: '',
          image: '',
          note_type: 'content',
          contentData: [
            {
              awareness_category_note_image: '',
              title: '',
              title_color_code: '',
              content: '',
              content_color_code: '',
              gradient_left_color_code: '',
              gradient_right_color_code: '',
            },
          ],
          error: {
            name: '',
            image: '',
            note_type: '',
          },
        },
      },
    });
  },

  // UPDATE AWARENESS PICTURE
  updateAwarenessPicture: (profile_pic) => {
    const { awarenessManagementState } = get();
    const { awarenessManagement } = awarenessManagementState;
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        awarenessManagement: {
          ...awarenessManagement,
          image: profile_pic,
        },
      },
    });
  },

  // UPDATE CATEGORY PICTURE
  updateCategoryPicture: (profile_pic) => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          image: profile_pic,
        },
      },
    });
  },
  handleContentDelete: (index) => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    const { contentData } = categoryManagement;
    const contentDataCopy = JSON.parse(JSON.stringify(contentData));
    contentDataCopy.splice(index, 1);

    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          contentData: contentDataCopy,
        },
      },
    });
  },
  addContentFnc: () => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    const { contentData } = categoryManagement;
    const contentDataCopy = JSON.parse(JSON.stringify(contentData));
    contentDataCopy.push({
      awareness_category_note_image: '',
      title: '',
      title_color_code: '',
      content: '',
      content_color_code: '',
      gradient_left_color_code: '',
      gradient_right_color_code: '',
    });
    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          contentData: contentDataCopy,
        },
      },
    });
  },

  handelTitleContentChange: (type, value, index) => {
    const { awarenessManagementState } = get();
    const { categoryManagement } = awarenessManagementState;
    const { contentData } = categoryManagement;
    const contentDataCopy = JSON.parse(JSON.stringify(contentData));
    contentDataCopy[index][type] = value;

    set({
      awarenessManagementState: {
        ...awarenessManagementState,
        categoryManagement: {
          ...categoryManagement,
          contentData: contentDataCopy,
        },
      },
    });
  },
}));
