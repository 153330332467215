/* eslint-disable react/no-unstable-nested-components */
import {
  endOfFnc,
  getDateFormatToString,
  startOfFnc,
  subtractFnc
} from '@hc/dayjs';
import { UseDrugAudit } from '@hc/store/backoffice/drugAudit';
import { SearchField, TransactionFilterIcon } from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { DrugAuditFilterDrawer } from '@hc/ui/components/backoffice/drugAuditFilterDrawer';
import { DrugAuditStepperDrawer } from '@hc/ui/components/backoffice/drugAuditStepperDrawer';
import { FilterTab } from '@hc/ui/components/backoffice/filterTab';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { TransactionsCard } from '@hc/ui/components/backoffice/transactionsCard';
import { queryClient } from '@hc/utils';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { DrugAudit_style } from './style';

export default function DrugAuditPage(props) {
  const { className = '', ...rest } = props;

  //   ------------------------LOCAL STATE---------------------------------
  const [drugAuditDrawerOpen, setDrugAuditOpen] = useState(false);
  const [drugAuditStepperDrawerOpen, setDrugAuditStepperOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //   -------------------------STORE---------------------------------------

  const {
    getAllDrugAuditData,
    drugAuditState,
    handleChange,
    getDrugAuditStatusTrackData,
    updateFromAndToDateAndTab,
    loading,
  } = UseDrugAudit((state) => ({
    getAllDrugAuditData: state.getAllDrugAuditData,
    drugAuditState: state.drugAuditState,
    handleChange: state.handleChange,
    getDrugAuditStatusTrackData: state.getDrugAuditStatusTrackData,
    updateFromAndToDateAndTab: state.updateFromAndToDateAndTab,
    loading: state.loading,
  }));

  const {
    drugAuditListData,
    drugAuditCount,
    search,
    tabIndex,
    drugAuditStatusTrackData,
    drugAuditStatsCardData,
  } = drugAuditState;

  const filterList = [
    'Today',
    'This week',
    'This month',
    'Last week',
    'Last month',
  ];

  const handleChangeFilter = async (event, newValue) => {
    let fromDat = '';
    let toDat = '';
    if (newValue === 0) {
      fromDat = startOfFnc(new Date(), 'day');
      toDat = endOfFnc(new Date(), 'day');
    } else if (newValue === 1) {
      fromDat = startOfFnc(new Date(), 'week');
      toDat = endOfFnc(new Date(), 'week');
    } else if (newValue === 2) {
      fromDat = startOfFnc(new Date(), 'month');
      toDat = endOfFnc(new Date(), 'month');
    } else if (newValue === 3) {
      const lastWeekDate = subtractFnc(new Date(), 1, 'week');
      fromDat = startOfFnc(new Date(lastWeekDate), 'week');
      toDat = endOfFnc(new Date(lastWeekDate), 'week');
    } else if (newValue === 4) {
      const lastMonthDate = subtractFnc(new Date(), 1, 'month');
      fromDat = startOfFnc(new Date(lastMonthDate), 'month');
      toDat = endOfFnc(new Date(lastMonthDate), 'month');
    }
    await updateFromAndToDateAndTab(
      new Date(fromDat),
      new Date(toDat),
      newValue,
    );
    queryClient.invalidateQueries({
      queryKey: ['drugAuditData'],
    });

    await getAllDrugAuditData(search);
  };

  // -----------------------------Pagination State ---------------------------
  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['drugAuditData'],
    });
    await getAllDrugAuditData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['drugAuditData'],
    });
    await getAllDrugAuditData(search, rowsPerPage, offset);
  };

  // ----------------------------------------Drawer Cl;ose-----------------------------
  const closeDrugAuditDrawer = () => {
    setDrugAuditOpen(false);
  };

  // ----------------------------------------Drug Audit Stepper Drawer Close-----------------------------
  const closeDrugAuditStepperDrawer = () => {
    setDrugAuditStepperOpen(false);
  };

  const logClick = async (id) => {
    queryClient.invalidateQueries({
      queryKey: ['drugAuditStatusTrackData'],
    });
    await getDrugAuditStatusTrackData(id);
    setDrugAuditStepperOpen(true);
  };

  const columns = [
    {
      field: 'patient_details',
      headerName: 'Patient Details',
      width: 160,
    },
    {
      field: 'appointment_type',
      headerName: 'Appt Type',
      width: 180,
    },

    {
      field: 'doctor_name',
      headerName: 'Doctor Name',
      width: 180,
    },

    {
      field: 'consultation_time',
      headerName: 'Consultation Timing',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.consultation_time, 'lll'),
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '12px',
            borderRadius: '6px',
            padding: '4px 8px',
          }}
          backgroundColor={`${
            params?.row?.rx_audit_status_id === 1
              ? '#ffffe6'
              : params?.row?.rx_audit_status_id === 2
              ? '#E8FCF0'
              : '#FEEAEA'
          }`}
          color={`${
            params?.row?.rx_audit_status_id === 1
              ? '#FACC15'
              : params?.row?.rx_audit_status_id === 2
              ? '#4CAF50'
              : '#F44F5A'
          }`}
        >
          {params?.row?.status_name}
        </Typography>
      ),
    },
    {
      field: 'log',
      headerName: 'Log',
      width: 90,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          onClick={() => logClick(params?.row?.id)}
          style={{ fontSize: '14px', color: '#007965', fontWeight: 500 }}
        >
          View
        </Link>
      ),
    },
  ];

  // -------------------TABLE DATA SEARCH------------------------------------
  const handleSearchFnc = async (newData) => {
    handleChange('search', newData);
    queryClient.invalidateQueries({
      queryKey: ['drugAuditData'],
    });

    await getAllDrugAuditData(newData);
  };

  const filterDrawerOpen = () => {
    setDrugAuditOpen(true);
  };

  //   --------------------------------INITIAL FETCH-------------------------------
  const initialData = async () => {
    const fromDatee = startOfFnc(new Date(), 'day');
    const toDatee = endOfFnc(new Date(), 'day');
    await updateFromAndToDateAndTab(new Date(fromDatee), new Date(toDatee), 0);
    queryClient.invalidateQueries({
      queryKey: ['drugAuditData'],
    });
    await getAllDrugAuditData('');
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{
        overflow: 'scroll',
        backgroundColor: '#F8F8F9',
        height: '92vh',
      }}
    >
      <Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {' '}
          <Typography sx={DrugAudit_style.statsFont}>Stats</Typography>
        </Stack>
        <Grid container spacing={2}>
          {drugAuditStatsCardData.map((data, i) => (
            <Grid item xs={3} key={i} height="100%">
              {' '}
              <TransactionsCard
                percentage={data?.percentage?.slice(1)}
                description={data.description}
                amount={data.count}
                positive={
                  Number(data?.percentage?.slice(0, -1)) > 0 ? true : false
                }
                showPercentage={data.percentage ? true : false}
                colorCode={data?.color ?? '#007965'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography sx={DrugAudit_style.statsFont}>Drug Audit</Typography>
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box
            sx={{
              backgroundColor: '#EDEFF2',
              borderRadius: '10px',
            }}
          >
            <FilterTab
              filterList={filterList}
              handleChange={handleChangeFilter}
              value={tabIndex}
              toggleContainerSx={{
                display: '-webkit-box',
                width: '646px',
              }}
              filterTabRootStyle={DrugAudit_style.filterTabRootSx}
            />{' '}
          </Box>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <SearchField
              searchFieldStyle={DrugAudit_style.searchFieldSx}
              placeholder="Search by Gender and Age"
              onSearch={search}
              setOnSearch={handleSearchFnc}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch sx={{ fontSize: '14px', width: '14px' }} />
                </IconButton>
              }
            />
            <Box
              data-testId="filter"
              sx={DrugAudit_style.filterContainerSx}
              onClick={filterDrawerOpen}
            >
              <TransactionFilterIcon />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={3}>
        {loading ? (
          <Box>
            <TableSkeletonLoader />
          </Box>
        ) : (
          <Box>
            <MasterTable
              columns={columns}
              data={drugAuditListData}
              customTableStyle={DrugAudit_style.dataTableCustomSx}
              hideFooter
              rootStyle={{ height: '68vh' }}
              rowHeight={60}
            />
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={drugAuditCount}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Box>
        )}
      </Box>

      <DrugAuditFilterDrawer
        drugAuditDrawerOpen={drugAuditDrawerOpen}
        closeDrugAuditDrawer={closeDrugAuditDrawer}
      />

      <DrugAuditStepperDrawer
        drugAuditStepperDrawerOpen={drugAuditStepperDrawerOpen}
        closeDrugAuditStepperDrawer={closeDrugAuditStepperDrawer}
        stepperData={
          Array.isArray(drugAuditStatusTrackData) &&
          drugAuditStatusTrackData?.length > 0
            ? drugAuditStatusTrackData
            : []
        }
      />
    </Box>
  );
}
